import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../../bit-components/button/Button"
import { Link } from "gatsby"

import "../css/pages/wisdom-for-wisdom.scss"
// import Seamless from "../../components/Seamless"

import TimedSwapElement from "@bit/azheng.joshua-tree.timed-swap-element"
import TimedElement from "@bit/azheng.joshua-tree.timed-element"

const W4WPage = () => {
  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        title="Learn about our Wisdom For Wisdom program"
        description="Follow the Wisdom For Wisdom steps to apply"
      />
      <div className="w4w-main">
        <div className="w4w-header">
          <img src="/Programs/w4w-logo-2021.svg" />
        </div>
        <div className="w4w-top-container">
          <div className="w4w-image">
            <img src="/Programs/w4w-hero-image-2021.jpg" />
          </div>
          <img className="w4w-logo" src="/Programs/w4w-logo-mark-2021.jpg" />
          <div className="image-overlay">
            <img
              className="w4w-left is-hidden-mobile"
              src="/Programs/w4w-left-section-2021-close.png"
            />
            <img
              className="w4w-left is-hidden-tablet"
              src="/Programs/w4w-left-section-2021-mobile.png"
            />
          </div>
          {/* <p className="w4w-top-container__text is-hidden-tablet">
            Apply for a chance to receive free wisdom teeth removal.
          </p> */}
        </div>
        <div className="w4w-mid-container">
          <div className="list-container">
            <div className="facts">
              <h3 className="list-header">The Facts About Wisdom Teeth:</h3>
              <ul className="wisdom-facts">
                <li>
                  • Wisdom teeth are the only teeth that don’t form before
                  birth.
                </li>
                <li>
                  • 85% of wisdom teeth will need to be removed at some point.
                </li>
                <li>
                  • Wisdom teeth are the last of the 32 adult permanent teeth to
                  develop and erupt.
                </li>
                <li>• Wisdom teeth are also known as third molars.</li>
                <li>
                  • Wisdom teeth got their nickname because they often grow in
                  during the “age of wisdom,” usually between the ages of 17 and
                  25.
                </li>
              </ul>
            </div>
          </div>
          <div className="mid-paragraph">
            <div>
              <p className="mid-text">
                Oral Surgery & Dental Implant Specialists South Carolina is
                happy to announce this year's Wisdom For Wisdom recipients.
              </p>

              <p>
                Payton, 18, from Summerville, recently graduated from R.B. Stall
                High School and will be continuing her education at Trident
                Technical College. Payton’s wisdom teeth have been causing her
                pain, but the cost of surgery has prevented her from being able
                to seek treatment. In her application she spoke of how much the
                Wisdom For Wisdom program would help her family financially by
                allowing them to save more for her education, and allowing her
                to concentrate on her schoolwork.
              </p>

              <p>
                Uriel, 17, from North Charleston is another graduate of R.B.
                Stall High School who also plans to attend Trident Technical
                College. Uriel’s mother has been concerned about his oral health
                because his wisdom teeth are already starting to grow in. In his
                application she shared that it is difficult to watch her son
                suffer in pain and not be able to do anything to fix it. The
                Wisdom For Wisdom program would provide an amazing opportunity
                for Uriel to get the relief he deserves and move forward with
                his education.
              </p>

              <p>
                Alyssa, 22, from Mount Pleasant is a 5th year senior at
                Charleston Southern University, working on her Bachelor's
                degree. The high cost of college has forced her to ignore her
                painful wisdom teeth and focus all of her finances on school. In
                her application Alyssa said that “the Wisdom For Wisdom program
                would help me so much financially and physically. I have had to
                put off getting my wisdom teeth out for about 5 years, simply
                because I can not afford it while paying for college.”
              </p>

              <p>
                Virginia, 20, from Mount Pleasant is a full-time student at
                Trident Technical College working three jobs to support herself.
                Although she has health insurance her jobs do not provide dental
                benefits, so she has had to ignore her wisdom teeth and oral
                health. In her application she wrote that “this opportunity
                would help our family and not burden my single-parent mom with
                the cost of getting my wisdom teeth out.”
              </p>

              <p>
                Follow all of our Wisdom For Wisdom recipients’ treatment
                journeys on{" "}
                <a
                  href="https://www.facebook.com/osscarolina/"
                  title="Follow us on Facebook"
                  target="_blank"
                >
                  Facebook
                </a>{" "}
                and{" "}
                <a
                  href="https://www.instagram.com/oss_carolina/"
                  title="Follow us on Instagram"
                  target="_blank"
                >
                  Instagram
                </a>
                .
              </p>

              <p>
                Learn more about{" "}
                <Link
                  to="/wisdom-teeth-removal-in-mount-pleasant-sc/"
                  title="Learn more about wisdom teeth removal"
                >
                  wisdom teeth removal
                </Link>{" "}
                and all of our{" "}
                <Link to="/procedures/" title="Learn more about our procedures">
                  procedures
                </Link>
                .
              </p>

              {/* <TimedSwapElement
                  swapDate='4 Jun 2021 09:00:00 CDT'
                  beforeElement={
                    <p>
                    The application period is open now through <b>June 3, 2021.</b>
                  </p>
                  }
                  afterElement={
                    <p><strong style={{color: "#006082", fontSize: "21px", lineHeight: "28px"}}>Thank you for your interest in our Wisdom For Wisdom program. Our 2021 application period is closed, but stay tuned on our Facebook page to hear about the 2022 program!</strong></p>
                  }
                /> */}
            </div>
          </div>
          <TimedElement endDate="4 Jun 2021 09:00:00 CDT">
            <div className="apply-container">
              <h3>How to Apply:</h3>
              <div className="apply-content">
                <div className="one-two-three-wrapper">
                  <div className="hl"></div>
                  <div className="one-two-three">
                    <div className="one-two-three__item">1</div>
                    <div className="one-two-three__item">2</div>
                    <div className="one-two-three__item">3</div>
                  </div>
                </div>
                <div className="steps">
                  <div>Watch a video about wisdom teeth extraction.</div>
                  <div>Take a short quiz.</div>
                  <div>Submit your application.</div>
                </div>
              </div>
            </div>
          </TimedElement>
        </div>
        <div className="w4w-bottom-container">
          <TimedElement endDate="4 Jun 2021 09:00:00 CDT">
            <div className="w4w-sub-paragraph">
              <h3>
                We wish every student the best as they pursue their academic
                endeavors.
              </h3>
            </div>
            <div className="w4w-button">
              <Button
                buttonText="WATCH VIDEO"
                href="/wisdom-step-1/"
                contained
              ></Button>
            </div>
          </TimedElement>
        </div>
      </div>
    </Layout>
  )
}

export default W4WPage
